/* You can add global styles to this file, and also import other style files */
@use '~@angular/material' as mat;
@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
@import '~@angular/material/theming';

// Define a custom typography config that overrides the font-family as well as the
// `headlines` and `body-1` levels.
$custom-typography: mat.define-typography-config(
  $headline: mat.define-typography-level(40px, 48px),
  $body-1: mat.define-typography-level(18px, 24px)
);

@include mat.all-component-typographies($custom-typography);


.home-link {
  .t {
    color: #F18C15;
    font-size: 18px;

  }
  .four {
    font-size: 11px;
  }
  .f {
    color: #19A6E2;
    font-size: 18px;
  }
}

body {
    background-color: #403F3F;
    color: #fcfcfc;
    // margin-bottom: 60px;
}
.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 60px; /* Set the fixed height of the footer here */
  line-height: 60px; /* Vertically center the text there */
  background-color: #f5f5f5;
}

.how-to-text {
  color: #403F3F;
}
.how-to-section {
  background-color: #FFFFFF;
  color: #403F3F;
  padding: 30px;
  height: 540px;
  width: 700px;
}

.how-to-box {
  background-color: gray;
}
